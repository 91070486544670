import { getOurPartnersProps, getSearchFormProps, getWhyUseCAPSectionProps } from "utils/queries";

import Image from "next/future/image";
import SEO from "components/seo";
import dynamic from 'next/dynamic'
import { getLayoutProps } from "components/layout";
import strapi from "utils/strapi";

const Divider = dynamic(() => import('components/atoms/divider'));
const FAQSection = dynamic(() => import('components/sections/faq-section'));
const InfoGrid = dynamic(() => import('components/sections/info-grid'));
const RecentBlogsSection = dynamic(() => import('components/sections/recent-blogs-section'));
const SearchBox = dynamic(() => import('components/search-results/other-pages/search-box'), {
  loading: () => <div className="bg-pink-500 min-h-[500px] lg:min-h-[450px]"></div>
});
const SearchResultsProvider = dynamic(() => import('components/search-results/provider'));
const InfoSection = dynamic(() => import('components/sections/info-section'));
const LogosSection = dynamic(() => import('components/sections/logos-section'));
const SearchBoxWrapper = dynamic(() => import('components/search-results/search-box-wrapper'));

// const outlineSize = `3px`;

export default function Home({
  title,
  seoProps,
  headerImage,
  searchFormProps,
  whyUseCAPSection,
  ourPartnersSection,
  faqSection,
  infoGrid,
  blogs,
  blogsSectionTitle,
}) {
  return (
    <div>
      <SEO title={title} {...seoProps} />
      <div className="relative">
        <div className="hidden lg:block absolute top-0 left-0 right-0 bottom-0 w-full overflow-hidden -z-10 bg-teal-500/70"></div>
        <div className="max-w-7xl mx-auto lg:py-12 z-50 flex flex-row">
          <div className="hidden lg:flex flex-col items-center justify-center px-10 flex-1">
            <div className="flex flex-col">
              <span className="text-6xl text-pink-500 transform -translate-x-8 translate-y-3 -rotate-12 origin-top-left font-handwritten">Save money on</span>
              <span className="font-headerblock text-8xl text-white uppercase" style={{
                // textShadow: `-${outlineSize} -${outlineSize} 0 #fff, ${outlineSize} -${outlineSize} 0 #fff, -${outlineSize} ${outlineSize} 0 #fff, ${outlineSize} ${outlineSize} 0 #fff`,
              }}>Airport Parking</span>
            </div>
          </div>
          <div className="lg:max-w-[60%] lg:ml-auto w-full">
            <SearchResultsProvider>
              <SearchBoxWrapper>
                <SearchBox {...searchFormProps} />
              </SearchBoxWrapper>
            </SearchResultsProvider>
          </div>
        </div>
        <div className="hidden lg:block absolute top-0 left-0 right-0 bottom-0 w-full overflow-hidden -z-20 bg-teal-500"></div>
        <div className="hidden lg:flex absolute top-0 left-0 right-0 bottom-0 w-full overflow-hidden -z-10 opacity-[20%] flex-col justify-end">
          <Image
            src={require("../images/car.png")}
            width={6693}
            height={2842}
            alt={"Car"}
            className="object-contain object-left-bottom w-1/2 h-1/2"
            priority
          />
        </div>
      </div>
      <InfoSection {...whyUseCAPSection} className="mt-10" />
      <LogosSection {...ourPartnersSection} />
      <Divider />
      <InfoGrid {...infoGrid} />
      {blogs && (<RecentBlogsSection blogs={blogs || []} title={blogsSectionTitle} />)}
      <FAQSection {...faqSection} />
    </div>
  );
}

export async function getStaticProps(ctx) {
  const {
    data: {
      attributes: { title, seo: seoProps, headerImage, faqSection, infoGrid, blogsSectionTitle },
    },
  } = await strapi("/api/homepage", {
    populate: [
      "seo",
      "seo.openGraphImage",
      "headerImage",
      "faqSection",
      "faqSection.questions",
      "infoGrid",
      "infoGrid.items",
      "infoGrid.items.image",
    ],
  });

  const { data: blogsRaw } = await strapi("/api/blog-posts", {
    populate: {
      thumbnailImage: {
          populate: '*'
      },
      category: {
          populate: '*'
      },
    },
    pagination: { limit: 3, start: 0 },
    sort: ['publishedAt:desc']
  });
  const blogs = blogsRaw.map(r => r.attributes);

  return {
    props: {
      layoutProps: await getLayoutProps(ctx),
      title,
      seoProps,
      searchFormProps: await getSearchFormProps(),
      whyUseCAPSection: await getWhyUseCAPSectionProps(),
      headerImage: headerImage || {},
      ourPartnersSection: await getOurPartnersProps(),
      faqSection,
      infoGrid,
      blogs,
      blogsSectionTitle: blogsSectionTitle || '',
    },
    revalidate: Number(process.env.REVALIDATE_INTERVAL || 3600),
  };
}
